import {axiosData} from "@/store/api/axios";

const state = () => ({
  promotors: null || JSON.parse(localStorage.getItem('promotors')),
  promotor: null || JSON.parse(localStorage.getItem('promotor')),
  promotorlinks: null || JSON.parse(localStorage.getItem('promotorlinks')),
});

const getters = {
  getPromotors(state) {
    return state.promotors;
  },
  getPromotor(state) {
    return state.promotor;
  },
  getPromotorLinks(state) {
    return state.promotorlinks;
  },
};

const actions = {
  setPromotor({ commit }, payload) {
    commit('SET_PROMOTOR', payload);
    payload
        ? localStorage.setItem('promotor', JSON.stringify(payload))
        : localStorage.removeItem('promotor');
  },
  setPromotors({ commit }, payload) {
    commit('SET_PROMOTORS', payload);
    payload
        ? localStorage.setItem('promotors', JSON.stringify(payload))
        : localStorage.removeItem('promotors');
  },
  setPromotorsLinks({ commit }, payload) {
    commit('SET_PROMOTORSLINKS', payload);
    payload
        ? localStorage.setItem('promotorlinks', JSON.stringify(payload))
        : localStorage.removeItem('promotorlinks');
  },
  async getPromxCoordinadoresByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/promsxcoord/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getLinksPromotoresEmail(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/email/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('promotores/setPromotorsLinks', data);
        this.dispatch('business/setNegocioPromotor', data[0]);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async damePromotorNegociosFiestasTodas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/fiestas/todas',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getPromMaximo(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/maximos',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getPromxCabezasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/promsxcabeza/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getCoordinadoresByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/coordinadores/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getCabezasByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/cabezas/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getCabezasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/cabezasxcoord/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaPromotor(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getPromotoresByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/negocio/lista/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getPromotoresByNegocioBase(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/negocio',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async editarPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/editar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaListasGlobalCrearPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/crearpromotores',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaListasGlobalVerReportes(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/verreporte',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaListasGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/listas',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaDiasGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/dias',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaCantGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/listas/cant',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabaActGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/listas/act',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getAllPromotorNegociosa(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/lista',
          payload,
          'post',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTipoListasByPromotorActualiza(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotortipolistas/actualiza',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTipoListasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotortipolistas/promotor/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getFiestasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/fiestas/'+tag,
          'get',
          false,
          false
      );

      if(data !== null && data !== undefined && data !== 400 && data !== 404){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getPromotorByHash(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/lista/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
};

const mutations = {
  SET_PROMOTORS(state, payload) {
    state.promotors = payload;
    localStorage.setItem('promotors', JSON.stringify(payload));
  },
  SET_PROMOTORSLINKS(state, payload) {
    state.promotorlinks = payload;
    localStorage.setItem('promotorlinks', JSON.stringify(payload));
  },
  SET_PROMOTOR(state, payload) {
    state.promotor = payload;
    localStorage.setItem('promotor', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
